
import { Vue, Options, setup } from "vue-class-component";
import {Inject, Prop} from "vue-property-decorator";

import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

import {
  FieldsModel,
  LeadsRequestModel,
  ListItem, MegaDataRequestModel,
  PurchasedFieldsModel,
  PurchasedLeadsModel,
  PurchaseLeadsRequestModel,
  RepurchaseLeadsRequestModel, StripeCheckoutModel, SupportRequestModel,
} from "@/model";


import { ILeadsService} from "@/service";
import { Settings } from "@/config";

import MegaDataConfirm from "./components/MegaDataConfirm.vue";
import CreatSite from "../Sites/components/CreateSite.vue";
import AssignUser from "../Users/components/AssignUser.vue";
import Pagination from "@/components/controls/Pagination.vue";
import PixelTracking from "@/views/Sites/components/Tracking.vue";
import {IMegaDataService} from "@/service/megadata.service";

@Options({
  components: {
    CreatSite,
    MegaDataConfirm,
    AssignUser
  },
    validations: {
      request: {
        numberOfRecords: { required }
      },
    }
})
export default class MegaData extends Vue {
  @Prop() order: PurchasedLeadsModel;
  @Inject("leadsService") leadsService: ILeadsService;
  @Inject("megaDataService") service: IMegaDataService;

  public request = new MegaDataRequestModel();

  public store = useStore();
  public loading: boolean = false;
  public fields: Array<FieldsModel> = [];
  public toggleMegaDataConfirm: boolean = false;
  public toggleCreateSiteModel: boolean = false;
  public fieldsError: boolean = false;
  public selectAll: boolean = false;
  public states: Array<string> = [];
  public selectedStates: Array<string> = [];
  public googleSearchKeywords:string;
  public selectedFields: Array<any> = [];
  public fieldsMessage:string;

  public stateList: Array<string> = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming"
  ];

  public v$ = setup(() => this.validate());

  mounted() {
    this.getAllFields([]);
    this.getStates();
    this.fieldsMessage = "Please Specify How Many records You " +
        "would like to Purchase, Minimum Record " +
        "Purchase amount is 100 Records";
  }

  validate() {
    return useVuelidate();
  }

  public onCloseSiteModel(trigger: boolean) {
    this.toggleCreateSiteModel = false;
  }
  public getStates() {
    for (let state in this.stateList) {
      this.states.push(state);
    }
  }
  public getAllFields(subscribedFields: Array<ListItem>) {
    this.loading = true;

    this.leadsService.getAllFields().then((response) => {
      this.loading = false;
      this.fields = response
          .filter((r) => !this.subscriptionMode || subscribedFields.some((f) => f.value == r.leadFieldName))
          .map((e) => ({
            displayName: e.displayName,
            leadFieldName: e.leadFieldName,
            credit:
                e.leadFieldName == "city"
                    ? 0
                    : e.credit,
            value:
                e.leadFieldName == "email" ||
                e.leadFieldName == "city" ||
                this.subscriptionMode ||
                e.credit == 0 ||
                (this.order &&
                    this.order.purchasedFields.some(
                        (f) => f.leadFieldName == e.leadFieldName
                    ))
                    ? true
                    : false,
            disabled:
                e.leadFieldName == "email" ||
                this.subscriptionMode ||
                e.credit == 0 ||
                (this.order &&
                    this.order.purchasedFields.some(
                        (f) => f.leadFieldName == e.leadFieldName
                    )),
          }));
    });
  }
  selectAllFields() {
    this.selectAll = !this.selectAll;

    this.fields.forEach((f) => {
      if (!f.disabled) f.value = this.selectAll;
    });
  }
  get subscriptionMode() {
    return Settings.SubscriptionMode;
  }

  public submit() {
    this.selectedFields = [];
    this.v$.$touch();

    if (!this.v$.$invalid) {
      this.loading = true;
      if(this.request.numberOfRecords <100)
      {
        this.fieldsError = true;
        this.fieldsMessage = "Please Specify How Many records You " +
            "would like to Purchase, Minimum Record " +
            "Purchase amount is 100 Records";
        this.loading = false;
      }
      else
      {
        this.fieldsError = false;
        this.fields.forEach((item) => {
          if (item.value)
            this.selectedFields.push({
              fieldName: item.leadFieldName,
              displayName: item.displayName,
              credits: item.credit ? item.credit : 0,
            });
        });
        if (this.selectedFields.length > 0) {
          this.loading = true;
          this.request.purchasedFields = this.selectedFields;
          this.request.states = this.selectedStates;
          this.request.googleSearchKeywords = this.googleSearchKeywords;
          this.service
              .sendMegaDataRequest(this.request)
              .then((response) => {
                this.loading = false;
                this.store.dispatch("showAlert", {
                  message:
                      "Thank you for submitting your requests for MegaData.  We'll get back to you soon.",
                  snackBarClass: "fa-check",
                  type: "success",
                });
                this.v$.$reset();
              });
        }
        else
        {
          this.fieldsError = true;
          this.fieldsMessage = "Please select at least one lead field";
          this.loading = false;
        }
      }
    }
    else
    {
      this.fieldsError = true;
      this.fieldsMessage = "Please input How Many Records >= 100";
      this.loading = false

    }
  }


  public cancel() {
    this.$emit("cancel");
  }
}
