
import { Options } from "vue-class-component";
import { BaseComponent } from "@/components/BaseComponent";
import { Inject } from "vue-property-decorator";
import { useStore } from "vuex";

import { ISitesService } from "@/service";
import {
  DataResponse,
  SitesRequestModel,
  SitesModel,
  PixelSiteRequestModel,
  CreateUserRequestModel,
  PixelInstallSiteRequestModel,
  PixelInstallSiteModel,
  SetStatCounterRequestModel,
} from "@/model";

import Pagination from "@/components/controls/Pagination.vue";
import CreatSite from "./components/CreateSite.vue";
import PixelTracking from "./components/Tracking.vue";
import PluginTracking from "./components/PluginTracking.vue";
import StripeSubscription from "./components/StripeSubscription.vue";

@Options({
  components: {
    Pagination,
    CreatSite,
    PixelTracking,
    PluginTracking,
    StripeSubscription,
  },
})
export default class Sites extends BaseComponent {
  @Inject("sitesService") service: ISitesService;

  public store = useStore();

  public toggleCreateSiteModel: boolean = false;
  public togglePixelSiteModel: boolean = false;
  public togglePluginSiteModel: boolean = false;
  public toggleCreateSubscriptionModel: boolean = false;

  public siteName: string = "";
  public siteId: number = 0;
  public pixelUrl: any = "";
  public pixelInstallCode: string = "";
  public pixelSiteName: string = "";
  public pixelInstallResponse: DataResponse<PixelInstallSiteModel> = new DataResponse();;

  public request = new SitesRequestModel();
    public response: DataResponse<SitesModel> = new DataResponse();
  public pixelActivationItem: SitesModel;

  public isAdmin: boolean = false;
  public isSuperAdmin: boolean = false;

  created() {
    let user: CreateUserRequestModel = this.store.getters.getCurrentUser;

    this.isAdmin = user.role.indexOf("ADMIN") > -1;
    this.isSuperAdmin = user.role.indexOf("SUPERADMIN") > -1;

  }

  mounted() {
    this.getAllSites();
  }

  public formatNumber2Decimals (num:any) {
    return parseFloat(num).toFixed(2)
  }

  public getAllSites() {
    this.request._loading = true;
    this.service.getSites(this.request).then((response) => {
      this.request._loading = false;
      this.response = response;
    });
  }

  public addSubscription()
  {
    this.toggleCreateSubscriptionModel = true
  }

  public controlWithPagination(action: string) {
    this.request.page =
      action == "prev" ? this.request.page - 1 : this.request.page + 1;
    this.getAllSites();
  }

  public onCloseSiteModel(trigger: boolean) {
    this.toggleCreateSiteModel = false;
    if (trigger) this.getAllSites();
  }

  public generatePixel(siteId: number, siteName: string) {
    const request = new PixelSiteRequestModel();
    request.siteId = siteId;
    this.service
      .generatePixel(request)
      .then((response) => {
        this.pixelUrl = `${response}`;
        this.pixelSiteName = siteName;
        this.togglePixelSiteModel = true;
      })
      .catch((error) => {
        this.store.dispatch("showAlert", {
          message: "You do not have permission to generate pixel for this site",
          snackBarClass: "fa-times",
          type: "error",
        });
      });
  }

  public isStatCounterEnabled(item: SitesModel)
  {
    const statInfo = JSON.parse(item.additionalInfo);
    return (statInfo.s == 1);

  }

  public getPluginAuthCode(item: SitesModel)
  {
    const statInfo = JSON.parse(item.additionalInfo);
    return statInfo.authCode;

  }

  public getPixelInstallData(siteId: number, siteName: string) {
    const request = new PixelInstallSiteRequestModel();
    request.siteId = siteId;
    this.service
        .getPixelInstallData(request)
        .then((response) => {
          if(response.status == "SUCCESS")
          {
            this.pixelInstallCode = response.pixelInstallCode;
            this.pixelSiteName = siteName;
            this.togglePixelSiteModel = true;
          }
          else
            if(response.status == "UNAUTHORIZED")
            {
              this.store.dispatch("showAlert", {
                message: response.message,
                snackBarClass: "fa-times",
                type: "error",
              });

            }
            else
            {
              this.store.dispatch("showAlert", {
                message: "You do not have permission to generate pixel for this site",
                snackBarClass: "fa-times",
                type: "error",
              });
            }
        })
        .catch((error) => {
          this.store.dispatch("showAlert", {
            message: "You do not have permission to generate pixel for this site",
            snackBarClass: "fa-times",
            type: "error",
          });
        });
  }

  public showWordpressPluginInstall(item: SitesModel) {
    this.pixelActivationItem = item;
    this.togglePluginSiteModel = true;
  }

  public changeStatus(site: SitesModel) {
    this.service
      .changeStatus(site.id, site.status == "APPROVED" ? "PAUSED" : "APPROVED")
      .then((response) => {
        this.store.dispatch("showAlert", {
          message: `Site ${
            site.status == "APPROVED" ? "paused" : "unpaused"
          } successfully`,
          snackBarClass: "fa-check",
          type: "success",
        });

        this.getAllSites();
      });
  }

  public setStatCounter(site: SitesModel) {
    const request = new SetStatCounterRequestModel();
    request.siteId = site.id;
    request.enable  = (!this.isStatCounterEnabled(site))?true:false;
    const optype = (request.enable)?"enable":"disable";
    this.confirm(`Are you sure you want to  ${optype} advanced stats`).then(
        (confirm: boolean) => {
          if (confirm) {
    this.service
        .setStatCounter(request)
        .then((response) => {
          if(response.status == "SUCCESS")
          {
            this.store.dispatch("showAlert", {
              message:response.message,
              snackBarClass: "fa-check",
              type: "success",
            });
          }else
          {
            this.store.dispatch("showAlert", {
              message:response.message,
              snackBarClass: "fa-times",
              type: "error",
            });

          }


          this.getAllSites();
        });
          }
        }
    );
  }

  changeActive(site: SitesModel) {
    if (site.enabledFlag) {
      this.service.disableSite(site.id).then((response) => {
        this.getAllSites();
      });
    } else {
      this.service.enableSite(site.id).then((response) => {
        this.getAllSites();
      });
    }
  }

  public closePixelModel() {
    this.togglePixelSiteModel = false;
  }

  public closePluginModel() {
    this.togglePluginSiteModel = false;
  }

  public closeCreateSubscriptionModel() {
    this.toggleCreateSubscriptionModel = false;
  }

  public deleteSite(item: SitesModel) {
    this.confirm(`Are you sure you want the site "${item.siteName}"?`).then(
      (confirm: boolean) => {
        if (confirm) {
          this.service.deleteSite(item.id).then((response) => {
            this.store.dispatch("showAlert", {
              message: "Site deleted successfully",
              snackBarClass: "fa-check",
              type: "success",
            });
            this.siteId = 0;
            this.siteName = "";
            this.getAllSites();
          })
        .catch((error) => {
          if (error.response.status == 400)
            this.store.dispatch("showAlert", {
              message: "BAD REQUEST: "+"Error in deleting site",
              snackBarClass: "fa-times",
              type: "error",
            });
          if (error.response.status == 500)
            this.store.dispatch("showAlert", {
              message: "Server Error: "+"Error in deleting site",
              snackBarClass: "fa-times",
              type: "error",
            });
          this.$emit("closeSiteModel", true);
        });
        }
      }
    );
  }

  public updateSiteId(site: SitesModel) {
    this.store.dispatch("updateSiteId", site.id);
    this.store.dispatch("updateSiteName", site.siteName);

    this.$router.push("/dashboard");

    return false;
  }
}
