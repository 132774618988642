
import { Vue } from "vue-class-component";
import { Prop, Inject } from "vue-property-decorator";
import { useStore } from "vuex";

import {
  FieldsModel,
  LeadsRequestModel,
  ListItem,
  PurchasedFieldsModel,
  PurchasedLeadsModel,
  PurchaseLeadsRequestModel,
  RepurchaseLeadsRequestModel,
} from "@/model";

import { ILeadsService, StripeService,ISitesService } from "@/service";
import { Settings } from "@/config";

export default class UploadFilePurchaseLeads extends Vue {
  @Prop() adminSiteId: number;
  @Prop() selectedLeads: Array<number>;
  @Prop() leadRequest: LeadsRequestModel;
  @Prop() totalLeads: number;
  @Prop() purchasedLead: Array<number>;
  @Prop() siteId: string;
  @Prop() purchaseType: string;
  @Prop() order: PurchasedLeadsModel;
  @Inject("leadsService") service: ILeadsService;
  @Inject("sitesService") siteService: ISitesService;
  templateUrl: string = require("../../../assets/ManualUpload_Template.csv");
  templateImage: string = "../../../img/ManualUpload_Template.png";

  public store = useStore();

  public fields: Array<FieldsModel> = [];
  public stepper: number = 1;
  public response: number = 0;
  public selectedFields: Array<any> = [];

  public insufficentCredits: boolean = false;
  public fieldsError: boolean = false;
  public selectAll: boolean = false;

  public loading: boolean = false;

  public isDryRun:boolean = false;

  mounted() {
  }



  public proceed2() {
    if(this.stepper == 1)
    {
      this.stepper = 2;
    }


  }

  public processServiceTitan() {
    this.loading = true;

let control: any = this.$refs.upload;

    if (control.files.length) {
      let dryRun: string = this.isDryRun?"yes":"no";
      this.siteService
          .uploadServiceTitan(control.files[0],dryRun)
          .then((response: any) => {
            this.loading = false;
            if (response.includes("SUCCESS:")) {
              this.store.dispatch("showAlert", {
                message: response,
                snackBarClass: "fa-check",
                type: "success",
              });
              this.close();
            } else {
              this.store.dispatch("showAlert", {
                message: response,
                snackBarClass: "fa-times",
                type: "error",
              });
            }
          }).catch((error: any) => {
        this.loading = false;
        this.store.dispatch("showAlert", {
          message: "Error in processing File, the File Type needs to be a csv and in correct format.",
          snackBarClass: "fa-times",
          type: "error",
        });
      });
    }
    else
    {
      this.loading = false;
      this.store.dispatch("showAlert", {
        message: "No file uploaded",
        snackBarClass: "fa-times",
        type: "error",
      });
    }

  }

  public close() {
    this.$emit("close", false);
  }

}
