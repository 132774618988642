
import { Vue } from 'vue-class-component';
import { Prop } from "vue-property-decorator";

export default class Selectbox extends Vue {

    @Prop() label: string;
    @Prop() value: string;
    @Prop() fields: Array<string>;

    public selectedItem: string = '';
    public showAutoComplete:boolean = false;

    created() {
        this.selectedItem = this.value;
    }

    public update(value: string) {
        this.selectedItem = value;
        this.showAutoComplete = false;
        this.$emit("update", this.selectedItem);
    }

    public clickOutSideAutoComplete() {
        if(this.showAutoComplete)   this.showAutoComplete = false;
    }

}
