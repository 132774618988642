import { InvoiceModel, ListItem, SubscriptionModel, SubscriptionProductModel } from '@/model';
import axios from 'axios';
import { AxiosResponse, AxiosError, AxiosRequestConfig, ResponseType } from 'axios';

export class StripeService {
    baseUrl: string = "https://api.stripe.com/v1";
    token: string = "Bearer sk_test_51IZJpNCuDNgKvbHKimT4BxJS33RjhjC8I2mgWxa4SXT92xmOj75tUIycI6rMMJHu6wVyODqRrZWJ5MO1HXLn1Qbh00q3tSyBU8";


    getCustomer(email: string) {
        const config: AxiosRequestConfig = {
            params: { email: email },
            headers: {
                "Authorization": this.token
            }
        };

        return axios.get<any>(`${this.baseUrl}/customers`, config).then(response => {
            if (response && response.data && response.data.data && response.data.data.length > 0)
                return response.data.data[0];

            return null;
        });
    }

    getSubscriptionProducts() {
        const config: AxiosRequestConfig = {
            headers: {
                "Authorization": this.token
            }
        };

        return axios.get<any>(`${this.baseUrl}/products`, config).then(response => {
            let products = new Array<SubscriptionProductModel>();

            response.data.data.forEach((item: any) => {
                let product = new SubscriptionProductModel();
                product.id = item.id;
                product.name = item.name;

                if (item.metadata && item.metadata.Subscription == "1") {
                    product.amount = item.metadata.Amount;
                    product.price = item.metadata.Price;
                    product.field = item.metadata.FieldName;
                    product.isBasePlan = item.metadata.BasePlan == "1";

                    products.push(product);
                }
            });

            return products;
        });
    }

    getSubscriptions(customerId: string) {
        const config: AxiosRequestConfig = {
            params: { customer: customerId },
            headers: {
                "Authorization": this.token
            }
        };

        return this.getSubscriptionProducts().then(products => {
            return axios.get<any>(`${this.baseUrl}/subscriptions`, config).then(response => {
                let subscriptions = new Array<SubscriptionModel>();

                response.data.data.forEach((item: any) => {
                    let subscription = new SubscriptionModel();
                    subscription.id = item.id;
                    subscription.startDate = new Date(item.start_date * 1000);
                    subscription.amount = 0;
                    subscription.fields = []

                    if (item.items) {
                        item.items.data.forEach((s: any) => {
                            let product = products.find(p => p.id == s.plan.product);
                            if (product) {
                                subscription.fields.push(new ListItem(product.name, product.field));
                                subscription.isBasePlan = product.isBasePlan;
                            }

                            subscription.amount += s.plan.amount / 100
                        });
                    }

                    subscriptions.push(subscription);
                });

                return subscriptions;
            });
        });
    }

    getInvoice(id: string) {
        const config: AxiosRequestConfig = {
            headers: {
                "Authorization": this.token
            }
        };

        return axios.get<any>(`${this.baseUrl}/invoices/${id}`, config).then(response => {
            let invoice = new InvoiceModel();
            invoice.id = response.data.id;
            invoice.date = new Date(response.data.created * 1000);
            invoice.amount = response.data.total / 100;

            return invoice;
        });
    }

    getInvoices(customerId: string) {
        const config: AxiosRequestConfig = {
            params: { customer: customerId },
            headers: {
                "Authorization": this.token
            }
        };

        return axios.get<any>(`${this.baseUrl}/invoices`, config).then(response => {
            let invoices = new Array<InvoiceModel>();

            response.data.data.forEach((item: any) => {
                let invoice = new InvoiceModel();
                invoice.id = item.id;
                invoice.date = new Date(item.created * 1000);
                invoice.amount = item.total / 100;

                invoices.push(invoice);
            });

            return invoices;
        });
    }
}