
import { Settings } from "@/config";
import { Vue, Options } from "vue-class-component";
import AvaliableLeads from "./AvaliableLeads/Index.vue";
import PurchasedLeads from "./PurchasedLeads/Index.vue";

@Options({
  components: {
    AvaliableLeads,
    PurchasedLeads,
  },
})
export default class Leads extends Vue {
  public selectedLeadsType:string | any = "Available";

  created() {
    if(this.$route.params.page)  this.selectedLeadsType = this.$route.params.page;
  }

  onPurchaseCompleted() {
    this.selectedLeadsType = 'Purchased';
  }

  get leadName() {
    return Settings.LeadName;
  }
}
