
import { Vue, Options } from "vue-class-component";
import { Inject } from "vue-property-decorator";
import { ICreditHistoryService } from "@/service";
import { useStore } from "vuex";
import {
  CreateUserRequestModel,
  CreditHistoryModel,
  CreditHistoryRequestModel,
} from "@/model";
import { Settings } from "@/config";
import axios, { AxiosRequestConfig } from "axios";

@Options({
  components: {},
})
export default class InvoiceDetail extends Vue {
  @Inject("CreditHistoryService") service: ICreditHistoryService;

  public store = useStore();

  public credit?: CreditHistoryModel = new CreditHistoryModel();

  mounted() {
    let invoiceNumber: string | any = this.$route.params.invoiceNumber;

    const request = new CreditHistoryRequestModel();
    this.service.getAllCreditHistory(request).then((response) => {
      this.credit = response.find((r) => r.id == parseInt(invoiceNumber));
    });
  }

  public isSubscriptionCredits(credit:any)
  {
    if(credit.type ==="SUBSCRIPTION_CREDIT_SUCCESS"||credit.type ==="SUBSCRIPTION_ADDITIONAL_CREDIT_SUCCESS" )
    {
      return true;
    }
    return false;
  }

  public isSubscriptionAdditionalCredits(credit:any)
  {
    if(credit.type ==="SUBSCRIPTION_ADDITIONAL_CREDIT_SUCCESS" )
    {
      return true;
    }
    return false;
  }

  download() {
    const config: AxiosRequestConfig = {
      headers: {
        "Content-Type": "application/json",
      },
      responseType: "blob",
    };

    const data: any = {
      fileName: `Receipt-${this.credit?.id}.pdf`,
      html: document.documentElement.innerHTML,
    };

    return axios
      .post<any>(
        `https://pdfgeneratoryectra.azurewebsites.net/api/Download?code=FPPpjiqwnMNFiSj11gAUGDRqNUawcjENTU6ofIgIm9bbhS7CAayW5Q==`,
        data,
        config
      )
      .then((response) => {
        const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement("a");
        fileLink.href = fileUrl;
        fileLink.setAttribute("download", data.fileName);
        fileLink.style.display = "none";
        document.body.appendChild(fileLink);

        fileLink.click();
        window.URL.revokeObjectURL(fileUrl);
        document.body.removeChild(fileLink);
      });
  }

  printWindow() {
    window.print();
  }

  get currentUser() {
    return this.store.getters.getCurrentUser;
  }

  get logo() {
    return `${document.location.protocol}//${document.location.host}${Settings.AppLogo}`;
  }

  get appName() {
    return `${Settings.AppName}`;
  }

  get address() {
    return Settings.AppAddress;
  }
}
