
import {Vue, Options, setup} from "vue-class-component";
import {Inject} from "vue-property-decorator";

import {useStore} from "vuex";
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";

import {ISupportService} from "@/service";
import {SupportRequestModel} from "@/model";
import {Settings} from "@/config";

@Options({
  validations: {
    request: {
      name: {required},
      email: {required},
      message: {required},
    },
  },
})
export default class Support extends Vue {
  @Inject("supportService") service: ISupportService;

  public request = new SupportRequestModel();
  public store = useStore();

  public v$ = setup(() => this.validate());


  validate() {
    return useVuelidate();
  }

  support() {
    this.v$.$touch();

    if (!this.v$.$invalid) {
      this.request._loading = true;
      this.service
          .sendFeedback(this.request)
          .then((response) => {
            this.request._loading = false;
            this.store.dispatch("showAlert", {
              message:
                  "Thank you for submitting your question to our support team.  We'll get back to you within 48-72 hours.",
              snackBarClass: "fa-check",
              type: "success",
            });
            this.v$.$reset();
            this.request = new SupportRequestModel();
          })
          .catch((error) => {
            this.request._loading = false;
            this.store.dispatch("showAlert", {
              message: "Something went wrong, please try again later",
              snackBarClass: "snack--bar__error"
            });
          });
    }
  }

  get supportLogo() {
    return `${document.location.protocol}//${document.location.host}${Settings.AppSupportLogo}`;
  }

  public isAerolead() {
    return Settings.AppFavIcon == 'aerolead';
  }
}

