
import { Vue, Options, setup } from "vue-class-component";
import { Inject } from "vue-property-decorator";

import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

import { IDataRequestFormService } from '@/service';
import { DataFormRequestModel, StatesResponseModel } from '@/model';

@Options({
  validations: {
    request: {
      industry: { required },
      state: { required },
      zipCode: { required }
    },
  },
})
export default class DataRequestForm extends Vue {
  @Inject('dataRequestFormService') service: IDataRequestFormService;

  public request = new DataFormRequestModel();

  public stateToggle:boolean = false;
  public states: Array<StatesResponseModel> = [];

  public store = useStore();
  public v$ = setup(() => this.validate());

  validate() {
    return useVuelidate();
  }

  created() {
  }

  mounted() {
    this.getStates();
  }

  public getStates() {
    this.service.getStates().then((response) => {
      this.states = response;
      this.request.state = this.states[0].stateName;
    });
  }

  public clickOutSideState() {
    this.stateToggle = false;
  }

  public sendRequest() {
    this.v$.$touch();
    
    if (!this.v$.$invalid) { 
      this.request._loading = true;
      this.service.sendDataRequest(this.request).then((response) => {
        this.request._loading = false;
        this.store.dispatch("showSnack", {
          message: "Done",
          snackBarClass: "snack--bar__success"
        });
        this.v$.$reset();
        this.request = new DataFormRequestModel();
        this.request.state = this.states[0].stateName;
      }).catch((error) => {
        this.request._loading = false;
        this.store.dispatch("showSnack", {
          message: "Something went wrong, please try again later",
          snackBarClass: "snack--bar__error"
        });
      })
    }
  }

}
