
import { Vue } from "vue-class-component";
import { useStore } from "vuex";

export default class CancelSubscription extends Vue {
    public store = useStore();

    mounted() {
        this.goToSitePage();
    }

    private goToSitePage() {
        this.$router.push('/sites');
    }
}
