
import { Vue, Options } from "vue-class-component";
import { Inject } from "vue-property-decorator";
import { useStore } from "vuex";

import { ILeadsService } from "@/service";
import {
  DataResponse,
  LeadsRequestModel,
  PurchasedLeadsModel,
  AdditionalDetailsModel,
  PurchasedFieldsModel,
} from "@/model";

import PurchaseLeads from "../components/PurchaseLeads.vue";
import AdditionalDetails from "../components/AdditionalDetailsModel.vue";
import DownloadFile from "./components/DownloadFile.vue";
import Pagination from "../../../components/controls/Pagination.vue";
import { Settings } from "@/config";

@Options({
  components: {
    PurchaseLeads,
    AdditionalDetails,
    DownloadFile,
    Pagination,
  },
})
export default class PurchasedLeads extends Vue {
  @Inject("leadsService") service: ILeadsService;

  public store = useStore();
  public subscription: any = null;

  public selectedLeads: Array<any> = [];

  public togglePurchaseModel: boolean = false;
  public toggleDetailsModel: boolean = false;
  public toggleDownloadFileModel: boolean = false;

  public request = new LeadsRequestModel();
  public response: DataResponse<PurchasedLeadsModel> = new DataResponse();
  public details: Array<AdditionalDetailsModel> = [];

  public selectedOrder: PurchasedLeadsModel = new PurchasedLeadsModel();
  public selectedPurchasedFields: Array<PurchasedFieldsModel> = [];

  public loading: boolean = false;

  created() {
    this.getLeads();
  }

  mounted() {
    this.subscription = this.store.subscribe((mutations, type) => {
      if (mutations.type == "onChangeSiteId") this.getLeads();
    });
  }

  unmounted() {
    if (this.subscription) this.subscription();
  }

  private getPurchaseType(item: PurchasedLeadsModel)
  {
    if(item.type != null && item.type == "AUTOLEAD")
    {
      return "AUTODATA";
    }
    return "";
  }
  private getLeads() {
    this.request._loading = true;
    this.request.ascending = false;
    this.request.sortColumn = "purchaseDate";
    this.request.siteId = this.store.getters.getSiteId;
    this.service.getPurchangedLeads(this.request).then((response) => {
      this.request._loading = false;
      this.response = response;
    });
  }

  public controlWithPagination(action: string) {
    this.request.page =
        action == "prev" ? this.request.page - 1 : this.request.page + 1;
    this.getLeads();
  }

  closeModel(value: boolean) {
    this.togglePurchaseModel = value;
  }

  updatePurchaseModel() {
    this.togglePurchaseModel = false;
    this.store.dispatch("updateCredits");
    this.getLeads();
    this.selectedLeads = [];
    this.selectedOrder = new PurchasedLeadsModel();
    this.store.dispatch("showSnack", {
      message: "Leads purchased sucessfully",
      snackBarClass: "snack--bar__success",
    });
  }

  public closeDetailsModel() {
    this.toggleDetailsModel = false;
  }

  public downloadFileCSV(orderId: number) {
    this.loading = true;
    this.service
      .exportFileCSV(`${this.store.getters.getSiteName}_Leads.csv`, orderId)
      .finally(() => (this.loading = false));
    this.selectedLeads = [];
  }

  public downloadFile(orderId: number) {
    this.loading = true;
    this.service
      .exportFile(`${this.store.getters.getSiteName}_Leads.xlsx`, orderId)
      .finally(() => (this.loading = false));
    this.selectedLeads = [];
  }

  public repurchaseLead(item: PurchasedLeadsModel) {
    this.togglePurchaseModel = true;
    this.selectedOrder = item;
  }

  public onCloseDownloadModel() {
    this.toggleDownloadFileModel = false;
  }

  get leads() {
    const leads = this.selectedLeads.reduce(
      (a, o) => (a.push(o.noOfLeads), a),
      []
    );
    return leads;
  }

  get purchasedLead() {
    const leads = this.selectedLeads.reduce(
      (a, o) => (a.push(o.purchasedLead), a),
      []
    );
    return leads;
  }

  get siteId() {
    return this.store.getters.getSiteId;
  }

  getFieldNames(item: PurchasedLeadsModel) {
    return item.purchasedFields
      ? item.purchasedFields
          .filter((f) => f != null)
          .map((f) => f.displayName)
          .join(", ")
      : "";
  }

  get leadName() {
    return Settings.LeadName;
  }
}
