
import { Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

export default class Pagination extends Vue {
  @Prop() totalResults: number;
  @Prop() showPage: number;
  @Prop() totalPages: number;
  @Prop() page: number = 1;

  public pageSizeOptions: Array<number> = [10, 25, 50];
  public pageSize: number = 50;
  public triggerAutocomplete: boolean = false;

  public prev() {
    this.onChange("prev");
  }

  public next() {
    this.onChange("next");
  }

  onChange(action: string) {
    this.$emit("applyPagination", action);
  }
}
