
import { Vue } from 'vue-class-component';
import { useStore } from 'vuex';

export default class AppAlert extends Vue {

  public store = useStore();

  dialog: boolean = false;
  message: string = "";
  className: string = "";

  mounted() {
    this.store.subscribe((mutation, state) => {
      if(mutation.type == 'onShowAlert') {
        this.message = state['ContextModule'].message;
        this.className = state['ContextModule'].snackBarClass;
        this.dialog = true;
      }
    });
  }

}
