import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pa--top-1" }
const _hoisted_2 = { class: "tabs ma--x-1 ma--top-1" }
const _hoisted_3 = ["data-tooltip"]
const _hoisted_4 = ["data-tooltip"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AvaliableLeads = _resolveComponent("AvaliableLeads")!
  const _component_PurchasedLeads = _resolveComponent("PurchasedLeads")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["tabs--item", {
          'tabs--item__active': _ctx.selectedLeadsType == 'Available',
        }]),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectedLeadsType = 'Available'))
      }, [
        _createElementVNode("div", {
          class: "bottom",
          "data-tooltip": `New ${_ctx.leadName}s for purchase.`
        }, "Available", 8, _hoisted_3)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(["tabs--item", {
          'tabs--item__active': _ctx.selectedLeadsType == 'Purchased',
        }]),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectedLeadsType = 'Purchased'))
      }, [
        _createElementVNode("div", {
          class: "bottom",
          "data-tooltip": `${_ctx.leadName.charAt(0).toUpperCase() + _ctx.leadName.slice(1)}s already purchased`
        }, "Purchased", 8, _hoisted_4)
      ], 2)
    ]),
    (_ctx.selectedLeadsType == 'Available')
      ? (_openBlock(), _createBlock(_component_AvaliableLeads, {
          key: 0,
          onPurchaseCompleted: _ctx.onPurchaseCompleted
        }, null, 8, ["onPurchaseCompleted"]))
      : _createCommentVNode("", true),
    (_ctx.selectedLeadsType == 'Purchased')
      ? (_openBlock(), _createBlock(_component_PurchasedLeads, { key: 1 }))
      : _createCommentVNode("", true)
  ]))
}