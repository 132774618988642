
import {Vue} from "vue-class-component";
import {Inject, Prop} from "vue-property-decorator";
import {useStore} from "vuex";

import {ISitesService} from "@/service";
import {SendMailRequest} from "@/model";
import {SitesModel, CreateUserRequestModel} from "@/model";
import {Settings} from "@/config";

export default class PluginTracking extends Vue {
  @Prop() pixelActivationItem: SitesModel;
  @Prop() isSuperAdmin: boolean;
  @Inject("sitesService") service: ISitesService;
  pluginZipUrl: string = require("../../../assets/"+this.appWordpressPluginZip);
 // pluginZipUrl: string = require("../../../assets/logo.png");

  termsAndConditions: boolean = false;
  pluginMessage: string = "";
  email: string = "";
  siteName: string = "";
  siteAuthCode: string = "";
  public userAlert: boolean = false;
  public userMessage: string;

  public store = useStore();
  public request = new SendMailRequest();
  public user: CreateUserRequestModel = new CreateUserRequestModel();


  created() {
    this.user = this.store.getters.getCurrentUser;
  }

  mounted() {
    this.pluginMessage = "Copy the information and paste it in your wordpress plugin settings page for site " + this.pixelActivationItem.siteUrl + " only:\n<br>";
    this.pluginMessage = this.pluginMessage + "1). Email ==> " + this.user.email + "\n<br>2).Site Name ==> " + this.pixelActivationItem.siteName + "\n<br>3). Site Authorization Code ==> " + this.pixelActivationItem.additionalInfo;
    this.email = this.user.email;
    this.siteName = this.pixelActivationItem.siteName;
    this.siteAuthCode = JSON.parse(this.pixelActivationItem.additionalInfo).authCode;
  }

  public copyClipboard() {
    this.store.dispatch("showSnack", {
      message: "Successfully Copied to Clipboard",
      snackBarClass: "snack--bar__success",
    });
    this.userAlert = true;
    this.userMessage = "Successfully Copied to Clipboard";
  }

  public clearPixel() {
    this.$emit("closePixel", false);
  }

  public sendMail() {
    this.request.message = this.pluginMessage;
    this.service.sendMail(this.request).then(
        (response) => {
          this.clearPixel();
          this.store.dispatch("showSnack", {
            message: `Email Successfully sent to ${this.request.email}`,
            snackBarClass: "snack--bar__success",
          });
          this.store.dispatch("showAlert", {
            message:
                `Email Successfully sent to ${this.request.email}`,
            snackBarClass: "fa-check",
            type: "success",
          });
        },
        (error) => {
          this.clearPixel();
          this.store.dispatch("showSnack", {
            message: `Email Successfully sent to ${this.request.email}`,
            snackBarClass: "snack--bar__success",
          });
          this.store.dispatch("showAlert", {
            message:
                `Email Successfully sent to ${this.request.email}`,
            snackBarClass: "fa-check",
            type: "success",
          });
        }
    );
  }

  get appWordpressPluginZip() {
    return Settings.AppWordpressPluginZip;
  }
}
