
import { Options, setup } from "vue-class-component";
import { BaseComponent } from "@/components/BaseComponent";
import { Prop, Inject } from "vue-property-decorator";

import useVuelidate from "@vuelidate/core";
import { required, minValue } from "@vuelidate/validators";

import { UsersResponseModel } from "@/model";
import { IUserService } from "@/service";

import { useStore } from "vuex";

@Options({
  validations: {
    password: {
      required,
    },
  },
})
export default class ChangePassword extends BaseComponent {
  @Prop() user: UsersResponseModel;

  @Inject("userService") service: IUserService;

  password: string = "";

  public v$ = setup(() => this.validate());
  public store = useStore();

  validate() {
    return useVuelidate();
  }

  public close() {
    this.$emit("close");
  }

  public assign() {
    this.v$.$touch();
    if (!this.v$.$invalid) {
      this.service
        .changePasswordByAdmin(this.user.email, this.password)
        .then((response:any) => {
          if (response === true)
          {
            this.store.dispatch("showAlert", {
              message: "Error in changing password",
              snackBarClass: "fa-times",
              type: "error",
            });
          }
          else
          {
            if(typeof(response.data) !== 'undefined')
            {
              this.store.dispatch("showAlert", {
                message: "Password change successful",
                snackBarClass: "fa-check",
                type: "success",
              });
            }
            else
            {
              this.store.dispatch("showAlert", {
                message: "Something went wrong, Please try again later",
                snackBarClass: "fa-times",
                type: "error",
              });
            }

          }
          this.$emit("close");
        })
        .catch((error) => {
          if (error.response.status == 400)
            this.store.dispatch("showAlert", {
              message: `<p>Error updating password.</p><p>${error.response.data.message}</p>`,
              snackBarClass: "fa-times",
              type: "error",
            });
        });
    }
  }
}
