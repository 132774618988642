
import { Vue, Options, setup } from "vue-class-component";
import { Inject } from "vue-property-decorator";

import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";

import { IAuthenticationService } from "@/service";
import { ForgotPasswordRequest } from "@/model";
import { Settings } from "@/config";

@Options({
  validations: {
    request: {
      email: { required, email },
    },
  },
})
export default class ForgotPassword extends Vue {
  @Inject("authService") service: IAuthenticationService;

  public store = useStore();
  public request = new ForgotPasswordRequest();
  public v$ = setup(() => this.validate());
  public loading: boolean = false;

  validate() {
    return useVuelidate();
  }

  forgotPassword() {
    this.v$.$touch();

    if (!this.v$.$invalid) {
      this.loading = true;
      this.service.forgotPassword(this.request).then(
        (response: any) => {
          if (response === true)
          {
            this.loading = false;
            this.store.dispatch("showSnack", {
              message: "Invalid user email",
              snackBarClass: "snack--bar__error",
            });
          }
          else
          {
            if(typeof(response.data) !== 'undefined')
            {
              this.loading = false;
              this.store.dispatch("showSnack", {
                message: "Reset password link sent to your email",
                snackBarClass: "snack--bar__success",
              });
            }
            else
            {
              this.loading = false;
              this.store.dispatch("showSnack", {
                message: "Something went wrong, Please try again later",
                snackBarClass: "snack--bar__error",
              });
            }

          }
        },
        (err: any) => {
          this.loading = false;
          this.store.dispatch("showSnack", {
            message: "Something went wrong, Please try agaan later",
            snackBarClass: "snack--bar__error",
          });
        }
      );
    }
  }

  get logo() {
    return Settings.AppLogo;
  }
}
