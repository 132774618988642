
import { Vue, Options } from "vue-class-component";
import { Inject } from "vue-property-decorator";
import { useStore } from "vuex";

import {
  DataResponse,
  ConsumerDataRequestModel,
  ConsumerDataResponseModel,
  CreateUserRequestModel,
} from "@/model";

import { IConsumerDataService } from "@/service";

import Pagination from "@/components/controls/Pagination.vue";

@Options({
  components: {
    Pagination,
  },
})
export default class ConsumerData extends Vue {
  @Inject("consumerService") service: IConsumerDataService;

  public store = useStore();
  public subscription: any = null;

  public request: ConsumerDataRequestModel = new ConsumerDataRequestModel();
  public response: DataResponse<ConsumerDataResponseModel> = new DataResponse();

  public showFilters: boolean = false;

  created() {
    let user: CreateUserRequestModel = this.store.getters.getCurrentUser;

    if (user.role != "ADMIN") this.$router.push("/dashboard");

    this.getConsumerData();
  }

  mounted() {
    this.subscription = this.store.subscribe((mutations, type) => {
      if (mutations.type == "onChangeSiteId") this.getConsumerData();
    });
  }

  public getConsumerData() {
    this.request.siteId = this.siteId;
    this.service.getConsumerData(this.request).then((response) => {
      this.response = response;
    });
  }

  public controlWithPagination(page: number, pageSize: number) {
    this.request.page = page;
    this.request.pageSize = pageSize;
    this.getConsumerData();
  }

  public applyFilters() {
    this.request.page = 1;
    this.request.pageSize = 10;
    this.showFilters = false;
    this.getConsumerData();
  }

  public clickOutSideFilter() {
    this.showFilters = false;
  }

  get siteId() {
    return this.store.getters.getSiteId;
  }
}
