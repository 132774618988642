
import { Vue, Options } from "vue-class-component";
import { StripeService } from "@/service";
import { useStore } from "vuex";
import { InvoiceModel } from "@/model";
import { Settings } from "@/config";

export default class InvoiceDetail extends Vue {
  public store = useStore();

  public credit: InvoiceModel = new InvoiceModel();

  mounted() {
    let invoiceNumber: string | any = this.$route.params.invoiceNumber;

    let service = new StripeService();

    service.getInvoice(invoiceNumber).then((response) => {
      this.credit = response;
    });
  }

  printWindow() {
    window.print();
  }

  get currentUser() {
    return this.store.getters.getCurrentUser;
  }

  get logo() {
    return Settings.AppLogo;
  }

  get appName() {
    return Settings.AppName;
  }

  get stripeId() {
    return this.store.getters.getStripeId;
  }

  get address() {
    return Settings.AppAddress;
  }
}
