
import {Vue, Options, setup } from "vue-class-component";
import { BaseComponent } from "@/components/BaseComponent";
import {Inject, Prop} from "vue-property-decorator";
import {useStore} from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

import {SitesSubscriptionModel,CreateUserRequestModel
,StripeSubscriptionUpdateInfo, AmountRequest,
  StripeSubscriptionUpdateRequestModel,AllCreditValueModel} from "@/model";
import {Settings} from "@/config";
import {IAppService} from "@/service/app.service";
import {IStripeSubscriptionService,ICreditHistoryService} from "@/service";

@Options({
  //GUi validations
  validations: {
    //Subscription validations
    stripeSubscriptionUpdate: {
      endDate: {
        required,
        dateValidation: function (value: any) {
          const regexp = /^(((0[13-9]|1[012])[-/]?(0[1-9]|[12][0-9]|30)|(0[13578]|1[02])[-/]?31|02[-/]?(0[1-9]|1[0-9]|2[0-8]))[-/]?[0-9]{4}|02[-/]?29[-/]?([0-9]{2}(([2468][048]|[02468][48])|[13579][26])|([13579][26]|[02468][048]|0[0-9]|1[0-6])00))$/;
          const validation = regexp.test(value);
          return validation;
        },
      },
    },
    amountRequest:{
      dollarAmount: {
        required,
        dateValidation: function (value: any) {
          const regexp = /^[0-9]{1,3}((\,[0-9]{3})*)[\.]{0,1}[0-9]{2}$/;
          const validation = regexp.test(value);
          return validation;
        },
      }
    }
  }
  })

export default class SiteSubscription extends BaseComponent {
  @Prop() activeSubscription: SitesSubscriptionModel ;
  @Prop() projectName: string ;
  @Prop() siteId: number ;
  @Inject("CreditHistoryService") creditHistoryService: ICreditHistoryService;
  @Inject("stripeSubscriptionService") stripeSubscriptionService: IStripeSubscriptionService;

  public v$ = setup(() => this.validate());
  public store = useStore();

  validate() {
    return useVuelidate();
  }

  public isAdmin = false;
  public isSuperAdmin: boolean = false;
  public isEditing:boolean = false;
  public stripeSubscriptionUpdate: StripeSubscriptionUpdateInfo = new StripeSubscriptionUpdateInfo();

  public stripeSubscriptionUpdateRequestModel:StripeSubscriptionUpdateRequestModel = new StripeSubscriptionUpdateRequestModel();
  public amountMessage:string ="";
  public invalidAmount:boolean = true;
  public endDateMessage:string ="";
  public invalidEndDate:boolean = true;
  public amountRequest = new AmountRequest();
  public loading: boolean = false;
  public creditValueResponse: Array<AllCreditValueModel> = [];




  created() {
    const user: CreateUserRequestModel = this.store.getters.getCurrentUser;
    this.isAdmin = user.role.indexOf("ADMIN") > -1;
    this.isSuperAdmin = user.role.indexOf("SUPERADMIN") > -1;
    this.getAllCreditValues();

  }

  mounted() {
    this.stripeSubscriptionUpdate.updateType = "AMOUNTANDENDDATE";
    this.stripeSubscriptionUpdate.endDate  = this.getFormattedDate(this.add_days(new Date(this.activeSubscription.endTime),1).toISOString())
  }

  private getAllCreditValues() {
    this.loading = true;
    this.creditHistoryService.getAllCreditValues().then((response) => {
      this.loading = false;
      this.creditValueResponse = response;
      this.amountRequest.dollarAmount = this.creditValueResponse[0].creditPoint+".00"
    });
  }


  //Checks if the Edit or submit  button needs to be disabled
  public isSubmitOrEditDisabled()
  {
    if(this.isEditing && (this.invalidAmount ||this.invalidEndDate))
    {
      return true;
    }

    if(this.isEditing && this.v$.stripeSubscriptionUpdate.$invalid)
    {
      return true;
    }
    return false;
  }

  //Function to add months to date.
  public  add_days(dt: Date, n: number)
  {

    return new Date(dt.setDate(dt.getDate() + n));
  }

  //Function to add months to date.
  public  add_months(dt: Date, n: number)
  {

    return new Date(dt.setMonth(dt.getMonth() + n));
  }

  //Check if teh subscription end date is valid
  public checkEndDateIfValid(isInvalid: boolean)
  {
    if(isInvalid)
    {
      this.endDateMessage= "Invalid End date";
      this.invalidEndDate= true;
      return false;
    }
    let startDateAddMonths = this.add_months(new Date(this.getFormattedDate(this.activeSubscription.startTime)),6);
    let endDateAddDays = this.add_days(new Date(this.getFormattedDate(this.activeSubscription.endTime)),1);
    let todayDateAddDays = this.add_days(new Date(),1);
    let endDate = new Date(this.stripeSubscriptionUpdate.endDate);
    if(endDate < startDateAddMonths)
    {
      this.endDateMessage= "End Date should be greater than than Start Date";
      this.invalidEndDate = true;
      return false;
    }

    if(endDate < todayDateAddDays)
    {
      this.endDateMessage= "End Date should be greater than Todays date";
      this.invalidEndDate = true;
      return false;
    }

    if(endDate < endDateAddDays)
    {
      this.endDateMessage= "End Date should be greater than Previous End date";
      this.invalidEndDate = true;
      return false;
    }


    this.endDateMessage= "";
    this.invalidEndDate= false;
    return false;
  }

  //Check if teh subscription amount is valid
  public checkAmountIfValid(isInvalid: boolean)
  {
    if(isInvalid)
    {
      this.amountMessage = "Please enter in format $ 0.00";
      this.invalidAmount = true;
      return !isInvalid;
    }
    let intAmount = parseInt(this.amountRequest.dollarAmount);
    let ceilAmount = Math.ceil(parseFloat(this.amountRequest.dollarAmount));
    this.stripeSubscriptionUpdate.amount = Math.round(parseFloat(this.amountRequest.dollarAmount));
    if(ceilAmount > intAmount)
    {
      this.amountMessage = "Please round up to $ "+ceilAmount+".00";
      this.invalidAmount = true;
      return false;
    }

    if(intAmount < this.creditValueResponse[0].creditPoint)
    {
      this.amountMessage = "Minimum allowed purchase amount is is: $ "+this.creditValueResponse[0].creditPoint+".00";
      this.invalidAmount = true;
      return false;

    }
    this.invalidAmount = false;
    this.stripeSubscriptionUpdate.amount = intAmount;
    return true;
  }
  private checkIfEndDateEditable()
  {
    return this.isEditing&&
        (this.stripeSubscriptionUpdate.updateType =="AMOUNTANDENDDATE"
            ||this.stripeSubscriptionUpdate.updateType =="ENDDATEONLY");
  }

  private checkIfAmountEditable()
  {
    return this.isEditing&&
        (this.stripeSubscriptionUpdate.updateType =="AMOUNTANDENDDATE"
            ||this.stripeSubscriptionUpdate.updateType =="AMOUNTONLY");
  }
  private editStripeSubscription()
  {

    if (this.activeSubscription.amount > this.creditValueResponse[0].creditPoint)
    {
      this.amountRequest.dollarAmount = this.activeSubscription.amount+".00";
    }
      this.isEditing = true;

  }

  private submitEditStripeSubscription()
  {
    this.stripeSubscriptionUpdateRequestModel.siteId = this.siteId;
    this.stripeSubscriptionUpdateRequestModel.subscriptionUpdateInfo = this.stripeSubscriptionUpdate;
    this.stripeSubscriptionService.updateStripeSubscription(this.stripeSubscriptionUpdateRequestModel)
        .then((response: any) => {
          if(response.includes("SUCCESS:"))
          {
            this.store.dispatch("showAlert", {
              message: response,
              snackBarClass: "fa-check",
              type: "success",
            });
            this.close();
          }
          else
          {
            this.store.dispatch("showAlert", {
              message: response,
              snackBarClass: "fa-times",
              type: "error",
            });
          }
        })
    this.isEditing = false;

  }
  private deleteStripeSubscription()
  {
    this.close();
    this.confirm(
        `Are you sure you want to End the Subscription for Project `+this.projectName+' ?'
    ).then((confirm: boolean) => {
      if (confirm) {
        this.stripeSubscriptionService.deleteStripeSubscription(this.siteId)
            .then((response: any) => {
              if(response.includes("SUCCESS:"))
              {
                this.store.dispatch("showAlert", {
                  message: response,
                  snackBarClass: "fa-check",
                  type: "success",
                });
                this.close();
              }
              else
              {
                this.store.dispatch("showAlert", {
                  message: response,
                  snackBarClass: "fa-times",
                  type: "error",
                });
              }
            })
      }
    });
  }

  public getFormattedDate(dt: string)
  {
    let myDate = new Date(dt);
    return ('00'+(myDate.getMonth()+1)).slice(-2)+"/" + ('00'+((myDate.getDate()))).slice(-2) + "/" + myDate.getFullYear();
  }


  public close() {
    this.$emit("close");
  }


  get appName() {
    return Settings.AppName;
  }
}
