
import { Vue } from "vue-class-component";
import { useStore } from "vuex";
import axios from "axios";
import { ListItem } from "@/model";



export default class OpenRefund extends Vue {
  public refundResponse: Array<ListItem> = [];
  public possibleRefunds: Array<any> = [];
  public toggleConfirmRefund: boolean = false;
  public store = useStore();
  public credits: number = 0;
  public userId: number = 0;

  mounted () {
    this.userId = this.store.getters.getCurrentUser["id"]; 
    this.credits = this.store.getters.getCurrentUser["credits"]
    axios
      .get("https://justinarnold.pythonanywhere.com/refunds/"+this.userId+"/")
      .then((response) => { 
        const allCharges = response.data['data'];
        for (let e in allCharges) {
          let transaction = allCharges[e];
          let transactionAge = (Date.now()- (transaction['created']*1000))/86400000
          let transactionDate = new Date(transaction['created']*1000).toLocaleDateString("en-US")
          let transactionAmount = transaction['amount']/100
          let transactionId = transaction['id']
          if (transactionAge <= 30 && (transactionAmount+100)<this.credits && transaction['captured'] == true && transaction['refunded'] != true) {
            let refundableOrder = [transactionDate,transactionAmount,transactionId];
            this.possibleRefunds.push(refundableOrder)
          }
        }
      }
    )
  }
  public cancel() {
    this.$emit("cancel");
  }
  public refundOrder(item: any) {
    axios
      .post('https://justinarnold.pythonanywhere.com/refunds/', {
        userId: item.userId,
        transactionId: item[2]
      }).then((response) => {
        alert('Success!')
        this.$emit("cancel");
      }) 
  }
}
