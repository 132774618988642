
import axios from "axios";
import { Vue, Options, setup } from "vue-class-component";
import { Prop, Inject } from "vue-property-decorator";

import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";
import {
  required,
  email,
  numeric,
  minLength,
  maxLength,
} from "@vuelidate/validators";

import { IUserService } from "@/service";
import { CreateUserRequestModel } from "@/model";

import Selectbox from "../../../components/controls/Selectbox.vue";

@Options({
  components: {
    Selectbox,
  },
  validations: {
    request: {
      firstName: { required },
      lastName: { required },
      email: { required, email },
      phone: {
        required,
        numeric,
        minLength: minLength(10),
        maxLength: maxLength(10),
      },
    },
  },
})
export default class CreateUser extends Vue {
  @Prop() data: any;
  @Prop() accessType: string;
  @Inject("userService") service: IUserService;

  public store = useStore();
  public request = new CreateUserRequestModel();

  public v$ = setup(() => this.validate());

  public loading: boolean = false;

  public roleType: Array<string> = ["ADMIN", "CLIENT"];

  validate() {
    return useVuelidate();
  }

  public created() {
    if (this.accessType == "Update User" || this.accessType == "Edit Profile")
      this.request = this.data;
    else this.request.role = "ADMIN";
  }

  public addUser() {
    this.v$.$touch();

    if (!this.v$.$invalid) {
      if (!this.request.credits) this.request.credits = 0;
      this.loading = true;
      axios.defaults.headers.common["Authorization"] = "";
      this.request.type = "ADDUSER";
      this.request.siteId = this.store.getters.getSiteId;
      this.service
        .addUser(this.request)
        .then((response) => {
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + this.store.getters.accessToken;

          this.loading = false;
          this.$emit("closeModel", true);
          this.store.dispatch("showAlert", {
            message: "User added sucessfully",
            snackBarClass: "fa-check",
            type: "success",
          });
        })
        .catch((error) => {
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + this.store.getters.accessToken;

          if (error.response.status == 400)
            this.store.dispatch("showAlert", {
              message: error.response.data,
              snackBarClass: "fa-times",
              type: "error",
            });
          this.loading = false;
        });
    }
  }

  public updateUser() {
    this.v$.$touch();

    if (!this.v$.$invalid) {
      this.loading = true;
      axios.defaults.headers.common["Authorization"] = "";
      this.request.type = "ADDUSER";
      this.request.siteId = this.store.getters.getSiteId;
      this.service
        .updateUser(this.request)
        .then((response) => {
          this.loading = false;
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + this.store.getters.accessToken;
            
          this.$emit("closeModel", true);
          this.store.dispatch("showAlert", {
            message: "User details updated successfully",
            snackBarClass: "fa-check",
            type: "success",
          });
          
        })
        .catch((error) => {
          if (error.response.status == 400)
            this.store.dispatch("showAlert", {
              message: error.response.data,
              snackBarClass: "fa-times",
              type: "error",
            });
          this.loading = false;
        });
    }
  }

  public onUpdateRole(request: CreateUserRequestModel, selectedRole: string) {
    request.role = selectedRole;
  }

  public close() {
    this.$emit("closeModel", false);
  }
}
