import { IBaseService, BaseService } from './base.service';
import { DataFormRequestModel, DataFormResponseModel, StatesResponseModel } from "@/model";

export interface IDataRequestFormService extends IBaseService<DataFormRequestModel, DataFormResponseModel> {
    sendDataRequest(request: DataFormRequestModel): Promise<DataFormResponseModel>;
    getStates(): Promise<Array<StatesResponseModel>>;
}

export class DataRequestFormService extends BaseService<DataFormRequestModel, DataFormResponseModel> implements IDataRequestFormService {

    constructor() {
        super('admin');
    }

    public sendDataRequest(request: DataFormRequestModel): Promise<DataFormResponseModel> {
        return this.post(request, 'dataRequest').then((response) => {
            return response;
        })
    }

    getStates(): Promise<Array<StatesResponseModel>> {
        return this.httpGet("admin/getStates", null).then((response) => {
            return response.data;
        });
    }
}