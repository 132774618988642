
import { Vue } from "vue-class-component";
import { Prop, Inject } from "vue-property-decorator";
import { useStore } from "vuex";

import {
  FieldsModel,
  LeadsRequestModel,
  ListItem,
  PurchasedFieldsModel,
  PurchasedLeadsModel,
  PurchaseLeadsRequestModel,
  RepurchaseLeadsRequestModel,
} from "@/model";

import { ILeadsService, StripeService,ISitesService } from "@/service";
import { Settings } from "@/config";

export default class UploadFilePurchaseLeads extends Vue {
  @Prop() adminSiteId: number;
  @Prop() selectedLeads: Array<number>;
  @Prop() leadRequest: LeadsRequestModel;
  @Prop() totalLeads: number;
  @Prop() purchasedLead: Array<number>;
  @Prop() siteId: string;
  @Prop() purchaseType: string;
  @Prop() order: PurchasedLeadsModel;
  @Inject("leadsService") service: ILeadsService;
  @Inject("sitesService") siteService: ISitesService;
  templateUrl: string = require("../../../assets/ManualUpload_Template.csv");
  templateImage: string = "../../../img/ManualUpload_Template.png";

  public store = useStore();

  public fields: Array<FieldsModel> = [];
  public stepper: number = 1;
  public response: number = 0;
  public selectedFields: Array<any> = [];

  public emailCreditsForField:number;
  public selectedPremiumFieldTotalCredits:number;

  public insufficentCredits: boolean = false;
  public fieldsError: boolean = false;
  public selectAll: boolean = false;

  public loading: boolean = false;

  mounted() {
    if (this.subscriptionMode) {
      let service = new StripeService();
      service
          .getSubscriptions(this.store.getters.getStripeId)
          .then((response) => {
            let subscribedFields = ([] as Array<ListItem>).concat(
                ...response.map((s) => s.fields)
            );

            if (subscribedFields.length) this.getAllFields(subscribedFields);
          });
    } else this.getAllFields([]);
  }

  public getPremiumFields(): Array<any>
  {
    let premiumFields: Array<any> = [];
    this.selectedFields.forEach((s: any) => {
      if(s.displayName !='IP Address' && s.displayName !='City'  && s.displayName !='State'
          && s.displayName !='Zip' && s.displayName !='Email Address' && s.displayName !='Middle Initial' && s.displayName !='Last Name')
      {
        if(s.displayName =='First Name')
        {
          s.displayName = 'Full Name'
        }
        premiumFields.push(s);
      }

    });
    return premiumFields;
  }

  public getPremiumFieldsTotalCreditsPerLead()
  {
    this.emailCreditsForField = 0
    this.selectedPremiumFieldTotalCredits = 0
    this.selectedFields.forEach((s: any) => {
      if(s.displayName !='IP Address' && s.displayName !='City'  && s.displayName !='State'
          && s.displayName !='Zip' && s.displayName !='Email Address' && s.displayName !='Middle Initial' && s.displayName !='Last Name')
      {
        if(s.displayName =='First Name')
        {
          s.displayName = 'Full Name'
        }
        this.selectedPremiumFieldTotalCredits += s.credits
      }

      if(s.displayName ==='Email Address')
      {
        this.emailCreditsForField = s.credits
      }


    });
  }
  public getAllFields(subscribedFields: Array<ListItem>) {
    this.loading = true;

    this.service.getAllFields().then((response) => {
      this.loading = false;
      this.fields = response
          .filter((r) => !this.subscriptionMode || subscribedFields.some((f) => f.value == r.leadFieldName))
          .map((e) => ({
            displayName: e.displayName,
            leadFieldName: e.leadFieldName,
            credit:
                e.leadFieldName == "city"
                    ? 0
                    : e.credit,
            value:
                e.leadFieldName == "email" ||
                e.leadFieldName == "city" ||
                this.subscriptionMode ||
                e.credit == 0 ||
                (this.order &&
                    this.order.purchasedFields.some(
                        (f) => f.leadFieldName == e.leadFieldName
                    ))
                    ? true
                    : false,
            disabled:
                e.leadFieldName == "email" ||
                this.subscriptionMode ||
                e.credit == 0 ||
                (this.order &&
                    this.order.purchasedFields.some(
                        (f) => f.leadFieldName == e.leadFieldName
                    )),
          }));
    });
  }

  public formatNumber2Decimals (num:any) {
    return parseFloat(num).toFixed(2)
  }

  public proceed2() {
    if(this.stepper == 1)
    {
      this.stepper = 2;
    }
    else
    {
      if(this.stepper == 2)
      {
        this.selectedFields = [];
        let firstName = this.fields.find((f) => f.leadFieldName == "firstName");
        if (firstName) {
          let middleName = this.fields.find((f) => f.leadFieldName == "middleName");
          let lastName = this.fields.find((f) => f.leadFieldName == "lastName");

          if (middleName) middleName.value = firstName.value;
          if (lastName) lastName.value = firstName.value;
        }

        this.fields.forEach((item) => {
          if (item.value)
            this.selectedFields.push({
              fieldName: item.leadFieldName,
              displayName: item.displayName,
              credits: item.credit ? item.credit : 0,
            });
        });

        if (this.selectedFields.length > 0) {
          this.loading = true;

          this.getPremiumFieldsTotalCreditsPerLead();
          let request: any;

          if (this.purchaseType == "repurchaseLead") {

          } else {
            this.loading = false;
            this.stepper = 3;
            let leadCount = 1;
            this.response =
                leadCount *
                this.selectedFields.map((s) => s.credits).reduce((a, b) => a + b);
          }
        } else this.fieldsError = true;
      }
    }
  }

  public purchaseLeads2() {
    this.loading = true;
    if (this.credits < this.response) this.insufficentCredits = true;
    let request: any;

    let control: any = this.$refs.upload;
    if (control.files.length) {
      this.siteService
          .uploadSiteTraffic2(this.adminSiteId, control.files[0],this.selectedFields.map((s) => ({"fieldName": s.fieldName})))
          .then((response: any) => {
            this.store.dispatch("showAlert", {
              message: response,
              snackBarClass: "fa-check",
              type: "success",
            });

            this.close();
          }).catch((error: any) => {
        this.store.dispatch("showAlert", {
          message: "Error in processing File, the File Type needs to be a csv and in correct format.",
          snackBarClass: "fa-times",
          type: "error",
        });
      });
    }
    else
    {
      this.store.dispatch("showAlert", {
        message: "No file uploaded",
        snackBarClass: "fa-times",
        type: "error",
      });
    }

    this.$emit("purchase");
    this.loading = false;
  }

  public proceed() {
    this.selectedFields = [];
    let firstName = this.fields.find((f) => f.leadFieldName == "firstName");
    if (firstName) {
      let middleName = this.fields.find((f) => f.leadFieldName == "middleName");
      let lastName = this.fields.find((f) => f.leadFieldName == "lastName");

      if (middleName) middleName.value = firstName.value;
      if (lastName) lastName.value = firstName.value;
    }

    this.fields.forEach((item) => {
      if (item.value)
        this.selectedFields.push({
          fieldName: item.leadFieldName,
          displayName: item.displayName,
          credits: item.credit ? item.credit : 0,
        });
    });

    if (this.selectedFields.length > 0) {
      this.loading = true;

      let request: any;

      if (this.purchaseType == "repurchaseLead") {
        request = new RepurchaseLeadsRequestModel();
        request.siteId = +this.siteId;
        request.purchaseFields = this.selectedFields;
        request.orderId = this.order.orderId;
        request.purchaseType = "REPURCHASE";

        this.service.getCredits(request).then((response) => {
          this.loading = false;
          this.stepper = 2;
          this.response = response;
        });
      } else {
        this.loading = false;
        this.stepper = 2;

        let leadCount = this.selectedLeads.length;
        if (this.leadRequest && this.totalLeads) leadCount = this.totalLeads;

        this.response =
            leadCount *
            this.selectedFields.map((s) => s.credits).reduce((a, b) => a + b);
      }
    } else this.fieldsError = true;
  }

  public checkCredits() {
    if (this.credits >= this.response) this.purchaseLeads2();
    else this.insufficentCredits = true;
  }

  public purchaseLeads() {
    this.loading = true;
    if (this.credits < this.response) this.insufficentCredits = true;
    let request: any;

    if (this.purchaseType == "repurchaseLead") {
      request = new RepurchaseLeadsRequestModel();
      request.siteId = +this.siteId;
      request.purchaseFields = this.selectedFields;
      request.orderId = this.order.orderId;
      request.purchaseType = "REPURCHASE";
    } else {
      request = new PurchaseLeadsRequestModel();
      request.siteId = +this.siteId;
      request.leads = this.leadRequest ? null : this.selectedLeads;
      request.leadRequest = this.leadRequest;
      request.purchaseFields = this.selectedFields;
      request.purchaseType = "PURCHASE";
    }

    this.service
        .purchaseLeads(this.purchaseType, request)
        .then((response) => {
          this.loading = false;
          this.store.dispatch("showAlert", {
            message: "Leads purchased successfully",
            snackBarClass: "fa-check",
            type: "success",
          });
          this.$emit("purchase");
        })
        .catch((error) => {
          this.loading = false;
        });
  }

  public backToFields() {
    if(this.stepper == 2)
    {
      this.stepper = 1;
    }
    else
    {
      if(this.stepper == 3)
      {
        this.stepper = 2;
      }
    }
  }

  selectAllFields() {
    this.selectAll = !this.selectAll;

    this.fields.forEach((f) => {
      if (!f.disabled) f.value = this.selectAll;
    });
  }

  public buyCredits() {
    let route = this.$router.resolve({ path: "/billing" });
    window.open(route.href, "_blank");
  }

  public close() {
    this.$emit("close", false);
  }

  get credits() {
    return this.store.getters.getCredits;
  }

  get subscriptionMode() {
    return Settings.SubscriptionMode;
  }
}
